<template>
  <div class="box">
    <van-image
      width="120"
      height="118"
      class="logo"
      :src="require('../../assets/images/logo.png')"
    />
    <div class="name">智能箱运维信息管理</div>
    <van-cell-group inset>
      <van-form>
        <van-field
          readonly
          clickable
          required
          name="picker"
          :value="form.orgName"
          label="组织"
          placeholder="点击选择组织"
          @click="showOrgPicker = true"
        />
        <van-field
          readonly
          clickable
          required
          name="picker"
          :value="form.versionName"
          label="批次"
          placeholder="点击选择批次"
          @click="showVersionPicker = true"
        />
        <van-field
          v-model="form.number"
          required
          name="箱编号"
          label="箱编号"
          placeholder="请输入"
        />
      </van-form>
    </van-cell-group>
    <div v-if="showBottomBtn" class="btn-box">
      <van-button block type="info" @click="onNext"
        >下一步</van-button
      >
    </div>
    <!-- 组织选择器 -->
    <van-popup v-model="showOrgPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="orgColumns"
        value-key="name"
        @confirm="onOrgConfirm"
        @cancel="showOrgPicker = false"
      />
    </van-popup>
    <!-- 批次选择器 -->
    <van-popup v-model="showVersionPicker" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="versionColumns"
        @confirm="onVersionConfirm"
        @cancel="showVersionPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getBoxOrgList, getOrgVersionList, checkNumber } from '@/api/box'

export default {
  data() {
    return {
      form: {
        number: "",
        orgName: '',
        orgId: '',
        versionName: '',
        versionId: ''
      },
      orgColumns: [],
      showOrgPicker: false,
      versionColumns: [],
      showVersionPicker: false,
      // bugfix 按钮会随着键盘弹起错位
      showBottomBtn: true,
      clientHeight: document.documentElement.clientHeight
    };
  },
  created() {
    this.getBoxOrgList()
  },
  mounted() {
    window.onresize = () => {
      if (this.clientHeight > document.documentElement.clientHeight) {
        this.showBottomBtn= false
      } else {
        this.showBottomBtn= true
      }
    }
  },
  methods: {
    // 获取组织
    async getBoxOrgList() {
      const res = await getBoxOrgList()
      this.orgColumns = res.data
    },
    // 获取组织下批次集合
    async getOrgVersionList() {
      this.form.versionName = ''
      this.form.versionId = ''
      const res = await getOrgVersionList(this.form.orgId)
      this.versionColumns = res.data
      if (!res.data.length) {
        Toast("该组织下没有对应批次信息！")
        return
      }
    },
    // 选择组织
    onOrgConfirm(value) {
      this.form.orgName = value.name;
      this.form.orgId = value.id;
      this.showOrgPicker = false;
      this.getOrgVersionList()
    },
    // 选择版本
    onVersionConfirm(value) {
      this.form.versionName = value.name;
      this.form.versionId = value.id;
      this.showVersionPicker = false;
    },
    // 获取箱信息
    async checkNumber() {
      const { versionId, number } = this.form
      const res = await checkNumber(versionId, number)
      return res.data
    },
    // 提交
    async onNext() {
      const { number, versionId, orgId } = this.form
      if (!orgId) {
        Toast("请选择组织")
        return
      }
      if (!versionId) {
        Toast("请选择批次")
        return
      }
      if (!number) {
        Toast("请填写箱编号")
        return
      }
      const isHas = await this.checkNumber()

      if (!isHas) {
        Toast("未找到该箱编号信息，请确认箱编号重新输入")
        return
      } else {
        const { orgId, versionId, number } = this.form
        this.$router.push({ path: '/boxinformation22', query: { orgId, versionId, number  } })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.box {
  padding-top: 24px;
  text-align: center;
  background: #fff;
  min-height: 100vh;
  position: relative;
  .logo {
    margin-top: 48px;
    margin-bottom: 24px;
  }
  .name {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323233;
    line-height: 33px;
    margin-bottom: 40px;
  }
  ::v-deep .van-cell {
    padding: 16px;
  }
  ::v-deep .van-field__label {
    color: #323233;
    font-size: 17px;
  }
  ::v-deep .van-field__value {
    font-size: 17px;
  }
  ::v-deep .van-field__control {
    color: #969799;
  }
}
.btn-box {
  position: absolute;
  bottom: 74px;
  width: 343px;
  left: 16px;
}
</style>